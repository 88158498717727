* {
    font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

#root {
    width: 100%;
    height: 100%;
}

body {
    font-size: 1rem;
    font-weight: 300;
    color: #3D516F;
    background-color: #fff
}

.nav-embellish {
    font-size: 72.5%;
    font-weight: 700;
    position: relative;
    top: -0.2rem;
}

.navbar {
    background: linear-gradient(0deg, #fffbe6, #fffbe6);
    box-shadow: 4px 2px 16px rgba(136, 165, 191, 0.48), -4px -2px 16px #FFFFFF;
    justify-content: space-between;
}

.navbar .logo-link {
    padding: 0;
}

.navbar .logo-link img {
    width: 2rem;
    height: 2rem;
}

.navbar .logo-link .logo-title {
    font-weight: 600;
    position: relative;
    top: -0.2rem;
    color: #3D516F;
}

@media (max-width: 576px) {
    .navbar .logo-link img {
        width: 1.75rem;
        height: 1.75rem;
    }

    .navbar .logo-link .logo-title {
    }
}

.navbar .navbar-toggler {
    border: 0;
}

@media (max-width: 576px) {
    .search-bar {
        margin-top: 1rem!important;
    }
}
