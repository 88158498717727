.app {
    text-align: center;
}

.more-container {
    margin-top: 6rem;
}

.more-menu {
    display: flex;
    flex-direction: row;
    z-index: 1;
    box-shadow: none !important;
}

.more-menu .nav-link {
    font-size: 1rem;
    font-weight: 700;
}

.more-menu .nav-link.network-toggle {
    padding: 0.5rem;
}

.more-menu .nav-link.active {
    color: #3D516F !important;
}

.ancillary-menus {
    display: flex;
    flex-direction: row;
}

.ancillary-menus button:focus {
    outline: none;
    border: none;
}

.ancillary-menus button:focus:not(:active) {
    box-shadow: none;
}

.ancillary-menus > .card {
    margin-right: 0.75rem;
    box-shadow: none;
}

.network-menus,
.mini-wallet,
.navigation-menus {
    height: 2.5rem;
    _min-width: 15rem;
    border-radius: 1rem;
    border-color: #fff;
}

.network-menus .card-body,
.mini-wallet .card-body,
.navigation-menus .card-body {
    height: 2.5rem;
    padding: 1rem;
}

.mini-wallet .card-body {
    _border: 1px solid #fff;
    padding: 0.125rem;
}

.mini-wallet .card-body .address-container {
    position: relative;
    height: 100%;
    background-color: #F7F8FA;
    border-radius: 1rem;
}

.mini-wallet .wallet-container {
    height: 100%;
}

.mini-wallet .wallet-container .balance {
    font-weight: 700;
}

.mini-wallet .address-container span {
    font-weight: 600;
    line-height: 1rem;
}

.mini-wallet .address-container .address-avatar {
    position: relative;
    margin-left: 0.25rem;
    overflow: hidden;
    border-radius: 50%;
    background-color: #cef8ff;
}

.mini-wallet .address-container img {
    height: 100%;
}

.navigation-menus {
    min-height: 2.5rem;
    min-width: 2.5rem;
    border-radius: 1rem;
}

.more-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 2.5rem;
    min-width: 2.5rem;
}

.network-menus .selected-network img {
    height: 1.5rem;
    margin-right: 0.25rem;
}

@media (max-width: 576px) {
    .ancillary-menus > .card {
        margin-right: 0.5rem!important;
    }

    .mini-wallet .card-body .address-container {
        width: 100%;
    }

    .mini-wallet .wallet-container .balance {
        display: none!important;
    }

    .network-menus .selected-network .network-name {
        display: none;
    }

    .network-menus .selected-network img {
        margin-right: 0;
    }
}

.ancillary-menus .network-menu {
    position: relative;
    min-width: 18rem;
    padding: 1rem;
    border-radius: 0.75rem;
    box-shadow: 0 2px 0 rgb(90 97 105 / 11%),
    0 4px 8px rgb(90 97 105 / 12%),
    0 10px 10px rgb(90 97 105 / 6%),
    0 7px 70px rgb(90 97 105 / 10%);
}

.ancillary-menus .network-menu .network-item {
    cursor: pointer;
}

.ancillary-menus .network-menu .network-item:not(:first-child),
.ancillary-menus .network-menu .network-item:not(:last-child) {
    margin: 0.625rem 0;
}

.network-menu img.network-logo {
    height: 2rem;
}

.ancillary-menus .network-menu .active-network {
    background-color: #ececf6;
    border-radius: 0.75rem;
}

.ancillary-menus .network-menu .active-network img {
    height: 1rem;
}

.active-network .interesting-link {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    width: 100%;
    font-size: 85%;
    font-weight: 600;
    color: #0f6674;
}

.active-network .interesting-link span:hover {
    text-decoration: underline;
}

.active-network .interesting-link i {
    margin-top: 0.125rem;
}

.network-menu .network-selection img {
    margin-right: 0.375rem;
}

.community-menu {
    position: relative;
    flex-direction: column;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    min-width: 5rem;
    width: 12rem;
    left: -10rem;
    box-shadow: 0 2px 0 rgb(90 97 105 / 11%),
    0 4px 8px rgb(90 97 105 / 12%),
    0 10px 10px rgb(90 97 105 / 6%),
    0 7px 70px rgb(90 97 105 / 10%);
}

.community-menu .dropdown-item {
    padding: 0;
}

.community-menu .dropdown-item:hover {
    background-color: transparent !important;
    color: #3D516F;
}

@font-face {
    font-family: "tklabs";
    src: url(./webfonts/scratch.eot?73664376);
    src: url(./webfonts/scratch.eot?73664376#iefi) format("embedded-opentype"),
    url(./webfonts/scratch.woff2?73664376) format("woff2"),
    url(./webfonts/scratch.woff?73664376) format("woff"),
    url(./webfonts/scratch.ttf?73664376) format("truetype"),
    url(./webfonts/scratch.svg?73664376#scratch) format("svg");

    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "pulsechain";
    src: url(./webfonts/pulsechain.eot?43089420);
    src: url(./webfonts/pulsechain.eot?43089420#iefix) format("embedded-opentype"),
    url(./webfonts/pulsechain.woff2?43089420) format("woff2"),
    url(./webfonts/pulsechain.woff?43089420) format("woff"),
    url(./webfonts/pulsechain.ttf?43089420) format("truetype"),
    url(./webfonts/pulsechain.svg?43089420#pulsechain) format("svg");

    font-weight: normal;
    font-style: normal;
}

.icon-pulsechain-logo:before {
    content: '\e800';
    margin-bottom: 0.25rem;
}

.pulsechain-icon {
    display: inline;
    font-family: "pulsechain", serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6667rem;
    vertical-align: middle;
    color: #fff;
    speak: none;
    text-decoration: inherit;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 0 2px 1.5px #0a040345;
}

.loading-container {
    min-height: 1rem;
}

.loading-ripple {
    display: inline-block;
    position: relative;
    width: 7rem;
    height: 7rem;
}

.loading-ripple div {
    position: absolute;
    border: 4px solid #3D516F;
    opacity: 1;
    border-radius: 50%;
    animation: loading-ripple 1.25s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes loading-ripple {
    0% {
        top: 45%;
        left: 45%;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0;
        left: 0;
        width: 90%;
        height: 90%;
        opacity: 0;
    }
}

div#loading-text {
    position: absolute;
    margin: 0 auto;
    top: 30%;
    height: 700px;
    user-select: none!important;
}

div#loading-text span {
    position: relative;
    font-size: 2rem;
    font-weight: bolder;
    color: inherit;
}

@-webkit-keyframes loading-animation {
    0% {
        transform: rotate(0deg) scale(1.5);
        color: #aaa;
    }

    10% {
        transform: rotate(0deg) scale(1.7);
        color: #ccc;
    }

    100% {
        transform: rotate(0deg) scale(1.2);
        color: transparent;
        text-shadow: #666 0 0 10px;
    }
}

div#loading-text div {
    position: relative;
    display: inline-block;
    -webkit-animation: loading-animation 2.2s infinite;
}

div#loading-text div.letter_container_1 {
    -webkit-animation-delay: 0s;
}

div#loading-text div.letter_container_2 {
    -webkit-animation-delay: .2s;
}

div#loading-text div.letter_container_3 {
    -webkit-animation-delay: .4s;
}

div#loading-text div.letter_container_4 {
    -webkit-animation-delay: .6s;
}

div#loading-text div.letter_container_5 {
    -webkit-animation-delay: .8s;
}

div#loading-text div.letter_container_6 {
    -webkit-animation-delay: 1s;
}

div#loading-text div.letter_container_7 {
    -webkit-animation-delay: 1.2s;
}